<template>
  <div>
    <div class="mass-action-settings">

      <el-select
        v-model="actionData.chief_type"
        style="width:350px"
        placeholder="Выберите тип руководителя/администратора"
        size="small"
      >
        <el-option
          v-for="type in $companyConfiguration.user.getChiefTypes()"
          :key="type"
          :label="$companyConfiguration.user.getChiefTypeShowName(type)"
          :value="type"
        >
        </el-option>
      </el-select>

      <div style="margin-top: 15px; margin-bottom: 10px">
        <add-users-dialog
          single-select
          title-text="Выбор сотрудника"
          add-button-text="Нажмите на строку для выбора"
          @users-selected="userSelected"
        >
          <el-button
            slot="reference"
            size="mini"
            icon="fas fa-person-booth"
          >
            Выбрать
          </el-button>
        </add-users-dialog>


      </div>
      <div style="margin-bottom: 25px">
        Пользователь: {{ user.full_name }}
      </div>






      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить орг. структуру?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
      :error-props="['id', 'full_name']"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "user-set-chief-action",
  mixins: [ActionTemplateMixin],
  components: {AddUsersDialog, MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.chief_type
        || !this.actionData.user_id;
    }
  },
  watch: {
    'user.id': function (newVal) {
      this.actionData.user_id = this.user.id;
    }
  },
  data() {
    return {
      user: {},
      actionData: {
        chief_type: null,
        user_id: null,
      }
    }
  },
  methods: {
    userSelected(user){
      this.user = user;
    }
  },
}
</script>
